import { useMoreAppClient } from "../context/MoreAppContext";
import { isNil } from "lodash-es";
import { searchEntry, searchEntryFallback } from "../utils/datasourceUtil";
import useStateFormId from "../state/useStateFormId";
import useStateFormVersionId from "../state/useStateFormVersionId";
import useAuth from "./useAuth";
import useDataSourceCollection from "./useDataSourceCollection";
import { useReadLocalStorage } from "usehooks-ts";
import useOnlineStatus from "./useOnlineStatus";

const useDataSourceEntrySearch = (): {
  isInitializing: boolean;
  getEntry: (id: string, dataSourceId: string) => Promise<Record<string, string> | undefined>;
} => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const formId = useStateFormId();
  const formVersionId = useStateFormVersionId();
  const dataSourceCollection = useDataSourceCollection();
  const experimentalOnlineSearchEnabled = useReadLocalStorage("onlineSearch");
  const { isOnline } = useOnlineStatus();

  const isInitializing =
    isNil(formId) ||
    isNil(formVersionId) ||
    isNil(client) ||
    isNil(customerId) ||
    isNil(dataSourceCollection) ||
    isNil(username);

  const getEntry = async (id: string, dataSourceId: string): Promise<Record<string, string> | undefined> => {
    if (isInitializing) {
      throw new Error("useDatasourceEntrySearch not initialized before calling getEntry");
    }
    // DEV-6130 Online should be the default after it's live
    if (!experimentalOnlineSearchEnabled || !isOnline) {
      return searchEntryFallback(id, customerId, dataSourceId, username, dataSourceCollection, client);
    }
    try {
      // Actually await the value instead of just returning the promise, to ensure we don't skip the fallback once it errors
      return await searchEntry(id, customerId, dataSourceId, formId, formVersionId, client);
    } catch {
      return searchEntryFallback(id, customerId, dataSourceId, username, dataSourceCollection, client);
    }
  };
  return { isInitializing, getEntry };
};

export default useDataSourceEntrySearch;
