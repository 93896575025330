import type { AxiosInstance, AxiosResponse } from "axios";
import axios from "axios";
import type { RxCollection } from "rxdb";
import { isEmpty } from "lodash-es";
import logger from "./logger";
import type { DataSource, DataSourceEntry, DataSourceMeta, SearchType } from "../types/Datasource";
import { DataSourceNoLocalFallbackError, DataSourceNotFoundError } from "../types/Datasource";
import { readDir, readFileAsText, rmDir, stat, writeFile, writeFileBlob } from "./fileSystemUtil";
import { IMAGE_OPT_URL, SEARCH_URL } from "../constants";
import type { Currency } from "../types/Currency";
import { formatCurrency } from "./formatter";
import { toIsoCurrency } from "./currencyUtil";
import { nowToISO } from "./dateUtil";
import type { SearchResult } from "../hooks/useDatasourceSearch";

const NOT_FOUND = 404;
const OK = 200;
const DATASOURCES_PATH = "datasources";

type DataSourceStatus = { version: string; searchType: "LIVE" | "LOCAL_FALLBACK" | "UNKNOWN" };

export const fetchDataSource = async (
  username: string,
  customerId: number,
  id: string,
  client: AxiosInstance,
  datasourceCollection: RxCollection<DataSourceMeta>,
): Promise<DataSource> => {
  const { version, searchType } = await getStatus(client, customerId, id, datasourceCollection);

  // Use useDatasourceSearch hook instead
  if (searchType === "LIVE") {
    // This datasource could have had a local fallback before, remove the data from the local device.
    await removeLocalDatasource(id, username, customerId);
    throw new DataSourceNoLocalFallbackError();
  }

  const localEntries = await fetchFromFilesystem(version, id, customerId, username);
  if (localEntries) {
    return { id, entries: deduplicateEntries(localEntries) };
  }

  try {
    const { data: entries } = await getDatasourceEntries(client, customerId, id, version);

    await persistDatasourceToFileSystem(username, customerId, entries, searchType, id, datasourceCollection);
    return { id, entries };
  } catch (e: any) {
    if (e.status === NOT_FOUND) {
      await removeLocalDatasource(id, username, customerId); // Datasource was removed, we shouldn't keep it
      throw new DataSourceNotFoundError(`Couldn't retrieve datasource version ${version}`);
    }

    if (e.status !== OK) {
      const fallbackEntries = await fetchLatestFromFilesystem(id, customerId, username);
      if (fallbackEntries) {
        return { id, entries: deduplicateEntries(fallbackEntries) };
      }
    }
    throw Error(`Could not fetch datasource, statusCode: ${e.status}`);
  }
};

export const prefetchDatasource = async (
  username: string,
  customerId: number,
  id: string,
  client: AxiosInstance,
  collection: RxCollection<DataSourceMeta>,
): Promise<void> => {
  const { version, searchType } = await getDatasourceStatus(client, customerId, id);

  if (searchType === "LIVE") {
    // This datasource could have had a local fallback before, remove the data from the local device.
    await removeLocalDatasource(id, username, customerId);
    await collection.upsert({ customerId, id, version, updatedAt: nowToISO(), searchType });
  } else {
    await prefetchLocalFallback(username, customerId, id, version, client, collection, searchType);
  }
};

const persistDatasourceToFileSystem = async (
  username: string,
  customerId: number,
  entries: DataSourceEntry[],
  searchType: SearchType,
  id: string,
  datasourceCollection: RxCollection<DataSourceMeta>,
): Promise<void> => {
  if (isEmpty(entries)) {
    return;
  }
  const { version } = entries[0];
  await stat({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}` }).catch(async () => {
    await removeLocalDatasource(id, username, customerId);
    await datasourceCollection.upsert({ customerId, id, version, updatedAt: nowToISO(), searchType });
    return writeFile({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${entries[0].version}`,
      data: JSON.stringify(entries),
    });
  });
};

const getDatasourceStatus = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
): Promise<DataSourceStatus> => {
  const { status, data } = await client.get(`/api/v1.0/client/customers/${customerId}/datasources/${id}/status`);

  if (status === NOT_FOUND) {
    throw new DataSourceNotFoundError(`Couldn't find datasource ${id} for customer ${customerId}`);
  }
  return data;
};

const getDatasourceEntries = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  version: string,
): Promise<AxiosResponse<DataSourceEntry[]>> =>
  client.get(`/api/v1.0/customers/${customerId}/datasources/${id}/entries/version/${version}`);

const getDatasourceEntriesBlob = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  version: string,
): Promise<AxiosResponse<any, any>> =>
  client.get(`/api/v1.0/customers/${customerId}/datasources/${id}/entries/version/${version}`, {
    responseType: "blob",
  });

const prefetchLocalFallback = async (
  username: string,
  customerId: number,
  id: string,
  version: string,
  client: AxiosInstance,
  collection: RxCollection<DataSourceMeta>,
  searchType: "LIVE" | "LOCAL_FALLBACK" | "UNKNOWN",
): Promise<void> => {
  try {
    await stat({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}` });
  } catch {
    const { status, data } = await getDatasourceEntriesBlob(client, customerId, id, version);

    if (status === OK) {
      await removeLocalDatasource(id, username, customerId);
      await collection.upsert({ customerId, id, version, updatedAt: nowToISO(), searchType });
      await writeFileBlob({
        path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}`,
        blob: data,
      });
      URL.revokeObjectURL(data);
    }
  }
};

export const fetchFromFilesystem = async (
  version: string,
  id: string,
  customerId: number,
  username: string,
): Promise<DataSourceEntry[] | undefined> => {
  try {
    const data = await readFileAsText({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}`,
    });
    return (await JSON.parse(data)) as DataSourceEntry[];
  } catch {
    return undefined;
  }
};

export const fetchLatestFromFilesystem = async (
  id: string,
  customerId: number,
  username: string,
): Promise<DataSourceEntry[] | undefined> => {
  try {
    const { files } = await readDir({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}`,
    });

    if (files.length === 0) {
      return undefined;
    }

    return await fetchFromFilesystem(files[0].name, id, customerId, username);
  } catch {
    return undefined;
  }
};

const getStatus = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  datasourceCollection?: RxCollection<DataSourceMeta>,
): Promise<DataSourceStatus> =>
  getDatasourceStatus(client, customerId, id).catch(async () => {
    const datasource = await datasourceCollection?.findOne(id).exec();
    if (!datasource) {
      throw new DataSourceNotFoundError();
    }
    return { version: datasource.version, searchType: datasource.searchType };
  });

const removeLocalDatasource = async (id: string, username: string, customerId: number): Promise<void> => {
  try {
    await rmDir({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}` });
  } catch (e) {
    logger.debug("Could not delete old datasources", e);
  }
};

export const getEnabledFields = (mapping?: Record<string, boolean>): string[] =>
  Object.entries(mapping ?? {})
    .filter(([, value]) => value)
    .map(([key]) => key);

export const getDatasourceImg = (url?: string): string | undefined =>
  url ? `${IMAGE_OPT_URL}/?url=${encodeURIComponent(url)}` : undefined;

export const getCatalogueItemTitle = (data: Record<string, any>, entryFields: string[]): any => {
  if (data.name) {
    return data.name;
  }
  return Object.entries(data)
    .filter(([key]) => entryFields.some((x) => x === key))
    .map(([, value]) => value)
    .filter((value) => !isEmpty(value))
    .join(", ");
};

export const asCurrency = (price: number, currency?: Currency, precision?: number): string | undefined => {
  if (Number.isNaN(price)) {
    return undefined;
  }
  return formatCurrency(price, "en-GB", {
    currency: toIsoCurrency(currency) ?? "EUR",
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: precision,
  });
};

export const deduplicateEntries = (entries: DataSourceEntry[]): DataSourceEntry[] => {
  const seenIds = new Set<string>();

  return entries.reduce((deduplicatedEntries: DataSourceEntry[], entry: DataSourceEntry) => {
    const dataId = entry.data.id;
    if (!seenIds.has(dataId)) {
      seenIds.add(dataId);
      deduplicatedEntries.push(entry);
    }
    return deduplicatedEntries;
  }, []);
};

export const searchEntryFallback = async (
  id: string,
  customerId: number,
  dataSourceId: string,
  username: string,
  dataSourceCollection: RxCollection<DataSourceMeta>,
  client: AxiosInstance,
): Promise<Record<string, string> | undefined> => {
  const dataSource = await fetchDataSource(username, customerId, dataSourceId, client, dataSourceCollection);
  return dataSource.entries.find((entry) => entry.id === id)?.data;
};

export const searchEntry = async (
  id: string,
  customerId: number,
  dataSourceId: string,
  formId: string,
  formVersionId: string,
  client: AxiosInstance,
): Promise<Record<string, string> | undefined> => {
  const { data: token } = await client!.post<string>(
    `/api/v1.0/customers/${customerId}/datasources/${dataSourceId}/token`,
    {
      formId,
      formVersionId,
      allowedFields: ["id"],
    },
  );
  const { data } = await axios.post<SearchResult>(
    `${SEARCH_URL}/search`,
    {
      query: id,
      filter: [],
      isExact: true,
      dataSourceId: dataSourceId,
      page: 1,
      pageSize: 2,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  if (data.results?.length !== 1) {
    return undefined;
  }
  return data.results[0];
};
