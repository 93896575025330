import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { isNil } from "lodash-es";
import useAuth from "../hooks/useAuth";
import uuidv4 from "../utils/uuid";
import useFormTokenDetails from "../hooks/useFormTokenDetails";
import DirectUrlExpiredPage from "./errorpages/DirectUrlExpiredPage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import OfflinePage from "./errorpages/OfflinePage";
import type { Form } from "../types/Folder";
import LoadingPage from "./LoadingPage";
import useDirectForm from "../hooks/useDirectForm";
import useFormVersion from "../hooks/useFormVersion";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import SentLandingPage from "./SentLandingPage";
import logger from "../utils/logger";
import DirectUrlForm from "../components/DirectUrlForm";
import useTheme from "../hooks/useTheme";
import useStatusBar from "../hooks/useStatusBar";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useFieldCollection from "../hooks/useFieldCollection";
import useSubmissionCollection from "../hooks/useSubmissionCollection";
import useRememberedFieldCollection from "../hooks/useRememberedFieldCollection";
import { nowToISO } from "../utils/dateUtil";
import { UploadManagerProvider } from "../context/UploadManagerContext";
import { type PreviewMode } from "../components/FormContentWrapper";
import { createNewFields } from "../utils/submissionUtil";
import useDeviceInfo from "../hooks/useDeviceInfo";
import useDatabase from "../hooks/useDatabase";
import useRxSubmission from "../hooks/useRxSubmission";

const DirectUrlPage: FC<{ preview?: PreviewMode }> = ({ preview }) => {
  const { token, formVersionId } = useParams<{ token: string; formVersionId: string }>();
  const submissions = useSubmissionCollection();
  const fieldCollection = useFieldCollection();
  const rememberedFields = useRememberedFieldCollection();
  const { database: db } = useDatabase();
  const { formTokenDetails, isLoadingFormTokenDetails } = useFormTokenDetails(token);
  const { form, isLoadingForm } = useDirectForm(formTokenDetails?.formId, formTokenDetails?.customerId);
  const { authorizeDirectUrl, deauthorizeDirectUrl, authorization } = useAuth();
  const { isOnline } = useOnlineStatus();
  const [searchParams, setSearchParams] = useSearchParams();
  const [routeId, setRouteId] = useState<string | null>(searchParams.get("id"));
  const [initState, setInitState] = useState<"pending" | "initializing" | "initialized">("pending");
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const { id: deviceId } = useDeviceInfo();
  const [isLoadingFields, setIsLoadingFields] = useState<boolean>(true);
  const submission = useRxSubmission(routeId);

  const { formVersion, isLoadingFormVersion } = useFormVersion(
    submission?.formVersionId,
    submission?.customerId,
    submission?.formId,
  );
  useSubmissionPageTitle(submission);

  const theme = useTheme(formVersion);
  useStatusBar(theme.color);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(authorization.type === "oauth");
      authorizeDirectUrl(token).catch((e) => {
        if (e.response.status === 401) {
          setIsUnauthorized(true);
        } else {
          logger.error("Couldn't authorize direct url", e);
        }
      });
    }
    return deauthorizeDirectUrl;
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewSubmission = useCallback(
    async (customerId: number, form: Form) => {
      if (initState !== "pending") {
        return;
      }
      const id = uuidv4();
      await submissions?.upsert({
        id,
        customerId,
        formId: form.id,
        formVersionId: formVersionId ?? form.publishedVersion.formVersion,
        status: "draft",
        form: {
          name: form.meta.name,
          description: form.meta.description,
          icon: form.meta.icon,
          iconColor: form.meta.iconColor,
        },
        meta: {},
        createdAt: nowToISO(),
        updatedAt: nowToISO(),
        sendType: "manual",
      });

      setSearchParams((prev) => {
        const updatedSearchParams = new Map(prev);
        updatedSearchParams.set("id", id); // Replace instead of adding extra search param
        return [...updatedSearchParams.entries()];
      });
      setRouteId(id);
    },
    [formVersionId, initState, setSearchParams, submissions],
  );

  useEffect(() => {
    if (isNil(form) || isNil(form.id) || isNil(formVersion) || isNil(submission)) {
      return;
    }

    db?.fields
      .find()
      .where("submissionId")
      .equals(submission.id)
      .exec()
      .then(async (fields) => {
        if (fields.length === 0) {
          await createNewFields(
            submission.id,
            formVersion,
            form.id,
            deviceId,
            "anonymous",
            db.fields,
            // @ts-expect-error this is what it is called in the database
            db["remembered-fields"],
          );
        }
        setIsLoadingFields(false);
      });
  }, [db, deviceId, form, formVersion, submission]);

  useEffect(() => {
    // Make sure proper database is loaded, could be switching auth
    const expectedDatabaseName = `moreapp-anonymous-rx15`;
    const isDatabaseInitialized =
      submissions?.database.name === expectedDatabaseName &&
      formTokenDetails &&
      form &&
      !submissions?.destroyed &&
      !rememberedFields?.destroyed;
    if (!isDatabaseInitialized) {
      return;
    }
    if (initState !== "pending") {
      return;
    }

    setInitState("initializing");
    rememberedFields?.find().remove();

    if (!routeId) {
      createNewSubmission(formTokenDetails.customerId, form)
        .catch((e) => logger.error("Couldn't create new submission", e))
        .finally(() => setInitState("initialized"));
    } else {
      submissions
        .findOne(routeId)
        .exec()
        .then((value) => {
          if (isNil(value)) {
            createNewSubmission(formTokenDetails.customerId, form).catch((e) =>
              logger.error("Couldn't create new submission", e),
            );
          }
        })
        .finally(() => setInitState("initialized"));
    }
  }, [
    authorization?.username,
    formTokenDetails,
    form,
    submissions,
    routeId,
    createNewSubmission,
    initState,
    rememberedFields,
  ]);

  if (submission?.status === "final") {
    return <SentLandingPage theme={theme} />;
  }

  if (!isOnline) {
    return <OfflinePage />;
  }

  if ((!isLoadingFormTokenDetails && isNil(formTokenDetails)) || (!isLoadingForm && isNil(form)) || isUnauthorized) {
    return <DirectUrlExpiredPage />;
  }

  if (isLoadingFormTokenDetails || isLoadingFormVersion || isNil(fieldCollection) || isLoadingFields) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_DIRECT_URL_TOKEN", value: isLoadingFormTokenDetails },
          { labelKey: "LOADING_ERROR_DIRECT_URL_FORM_VERSION", value: isLoadingFormVersion },
          { labelKey: "LOADING_ERROR_DIRECT_URL_DATABASE", value: !fieldCollection },
          { labelKey: "LOADING_ERROR_DIRECT_URL_FIELDS", value: isLoadingFields },
        ]}
      />
    );
  }

  if (!isLoadingFormVersion && isNil(formVersion)) {
    return isOnline ? <DirectUrlExpiredPage /> : <OfflinePage />;
  }

  if (isNil(submission) || isNil(formVersion)) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return (
    <UploadManagerProvider>
      <DirectUrlForm
        preview={preview}
        formVersion={formVersion}
        theme={theme}
        submission={submission}
        fieldCollection={fieldCollection}
        isLoggedIn={isLoggedIn}
      />
    </UploadManagerProvider>
  );
};
export default DirectUrlPage;
