import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { dehydrate, useQueryClient } from "@tanstack/react-query";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import Content from "../components/Content";
import logger from "../utils/logger";
import useAuth from "../hooks/useAuth";
import { migrate } from "../database/migration/00_legacy-app-to-current/migrationUtil";
import { useMoreAppClient } from "../context/MoreAppContext";
import useToasts from "../hooks/useToasts";
import ImpersonateUserDrawer from "../components/ImpersonateUserDrawer";
import useApiPermission from "../hooks/useApiPermission";
import Page from "../components/Page";
import { Text } from "../storybook/components/Text/Text";
import { Header } from "../storybook/components/Header/Header";
import { Title } from "../storybook/components/Title/Title";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import useDevMode from "../hooks/useDevMode";
import { Navigate, useSearchParams } from "react-router-dom";
import { useImpersonation } from "../hooks/useImpersonation";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";

const DeveloperPage: FC = () => {
  const queryClient = useQueryClient();
  const { authorization, impersonatedUser } = useAuth();
  const client = useMoreAppClient();
  // useNavigate or useNavigateAsync refuse to work (one only changes the url, the other does nothing at all), presumably due to
  // a lack of actual user interaction with the page, the fact that it is in a promise or from a quirk with our router setup.
  // This is a workaround that /does/ work using a <Navigate to={navigate} /> tag instead. Deemed as fine as this is just a developer page.
  const [navigate, setNavigate] = useState<string>();
  const { showToast } = useToasts();
  const [isDevMode, setDevMode] = useDevMode();
  const [sorting, setSorting] = useLocalStorage("alphabeticalSorting", false);
  const [onlineSearch, setOnlineSearch] = useLocalStorage("onlineSearch", false);
  const [showImpersonationModal, setShowImpersonationModal] = useState(false);
  const { isSuperAdmin } = useApiPermission();
  const [searchParams, setSearchParams] = useSearchParams();
  const { attemptImpersonation, clearImpersonation } = useImpersonation();
  const { t } = useTranslation();

  const currentCommit = process.env.VITE_COMMIT_SHA;

  useEffect(() => {
    const impersonate = searchParams.get("impersonate");
    if (impersonate) {
      setSearchParams({});

      attemptImpersonation(impersonate).then((success) => {
        if (!success) {
          return;
        }
        setNavigate("/folders");
      });
    }
  }, [attemptImpersonation, isDevMode, isSuperAdmin, navigate, searchParams, setSearchParams, showToast]);

  if (!isDevMode) {
    return <Navigate to="/settings" />;
  }

  return (
    <Page>
      {navigate && <Navigate to={navigate} />}
      <Header
        className="mt-safe"
        title="Developer"
        backBtn={{ ariaLabel: t("BACK"), onClick: () => setNavigate("/settings") }}
      />
      <Content>
        <Section>
          <div className="col-span-2 row-start-2 flex flex-col gap-y-1 sm:row-start-1">
            {currentCommit && (
              <Text>
                Current commit: <b>{currentCommit}</b>
              </Text>
            )}
            <Text>
              Alphabetical Sorting: <b>{sorting ? "On" : "Off"}</b>
            </Text>
            <Text>
              Online search: <b>{onlineSearch ? "On" : "Off"}</b>
            </Text>
            {impersonatedUser && (
              <Text>
                Impersonating: <b>{impersonatedUser}</b>
              </Text>
            )}
          </div>
          <IconAndTextButton
            icon="BanIcon"
            variant="primary"
            label="Disable Developer mode"
            className="row-start-1 sm:col-end-4"
            onClick={() => {
              setDevMode(false);
            }}
          />
        </Section>

        <Divider />

        <Section title="General">
          <TextButton
            label="Throw new error"
            onClick={() => {
              throw new Error("Break!");
            }}
          />
          <TextButton label="Toggle sorting on alphabet" onClick={() => setSorting(!sorting)} />
        </Section>

        <Divider />

        <Section title="React Query">
          <TextButton
            label="Dump React Query state to logs"
            onClick={() => {
              logger.log("React Query state", dehydrate(queryClient, {}));
            }}
          />
          <TextButton label="Clear React Query state" onClick={() => queryClient.resetQueries()} />
        </Section>

        <Divider />

        <Section title="Migration">
          <TextButton label="Migrate registrations" onClick={() => migrate(authorization.username!, client!)} />
        </Section>

        <Divider />

        <Section title="UI">
          <TextButton label="Show toast" onClick={() => showToast({ message: "Testerdetest" })} />
        </Section>

        <Divider />

        <Section title="Searching">
          <TextButton label="Enable online search" onClick={() => setOnlineSearch((prev) => !prev)} />
        </Section>

        {isSuperAdmin && (
          <>
            <Divider />
            <Section title="Impersonation">
              <TextButton label="Impersonate User" onClick={() => setShowImpersonationModal(true)} />
              <ImpersonateUserDrawer open={showImpersonationModal} onClose={() => setShowImpersonationModal(false)} />
              {impersonatedUser && <TextButton label="Clear" onClick={() => clearImpersonation()} />}
            </Section>
          </>
        )}
      </Content>
    </Page>
  );
};

export default DeveloperPage;

type SectionProps = {
  title?: string;
  children: ReactNode;
};

const Section = ({ title, children }: SectionProps): JSX.Element => (
  <section>
    {title && (
      <Title as="h2" size="lg" className="mb-1">
        {title}
      </Title>
    )}
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 xl:grid-cols-3">{children}</div>
  </section>
);

const Divider = (): JSX.Element => <hr role="presentation" className="my-6" />;
