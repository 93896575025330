import type { RxCollection, RxDatabase, RxDocument, RxStorage } from "rxdb";
import { addRxPlugin, createRxDatabase } from "rxdb";
import { getRxStorageSQLite, getSQLiteBasicsCapacitor } from "rxdb-premium/plugins/storage-sqlite";
import { setPremiumFlag } from "rxdb-premium/plugins/shared";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration-schema";
import { RxDBCleanupPlugin } from "rxdb/plugins/cleanup";
import { RxDBDevModePlugin, disableWarnings } from "rxdb/plugins/dev-mode";
import compress from "graphql-query-compress";
import { wrappedKeyCompressionStorage } from "rxdb/plugins/key-compression";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { CapacitorSQLite, SQLiteConnection } from "@capacitor-community/sqlite";
import { Capacitor } from "@capacitor/core";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { RxDBLocalDocumentsPlugin } from "rxdb/plugins/local-documents";
import { wrappedLoggerStorage } from "rxdb-premium/plugins/logger";
import type { Submission } from "../types/Submission";
import {
  fieldMetaSchema,
  fieldSchema,
  rememberedFieldSchema,
  rememberedSearchQuerySchema,
  submissionSchema,
  unsyncedParentFieldSchema,
} from "../database/submissionSchema";
import type { Field, FieldMeta, RememberedField, RememberedSearchQuery, UnsyncedParentField } from "../types/Field";
import type { DataSourceMeta } from "../types/Datasource";
import { dataSourceSchema } from "../database/dataSourceSchema";
import {
  migrationCompressedFields,
  migrationLocalFallback,
  migrationRxdb14,
  migrationSubmittedAt,
  migrationUploadStatus,
  migrationWithOrder,
} from "../database/migration/rxdb/migrationUtil";
import type { FormVersionEntry } from "../database/formVersionSchema";
import { formVersionSchema } from "../database/formVersionSchema";
import logger from "./logger";
import { DatabaseError } from "../errors/DatabaseError";

addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBLocalDocumentsPlugin);

if (process.env.VITE_ENV !== "production") {
  disableWarnings();
  addRxPlugin(RxDBDevModePlugin); // add checks and validations to RxDB, ensuring proper RxDB API use (https://rxdb.info/dev-mode.html)
}

// disable the console warning about premium options that are faster than dexie, because we're using it.
setPremiumFlag();

// Submissions
export type SubmissionDocument = RxDocument<Submission>;

// Fields
export type FieldDocument = RxDocument<Field>;

export type DBCollections = {
  submissions: RxCollection<Submission>;
  fields: RxCollection<Field>;
  rememberedFields: RxCollection<RememberedField>;
  rememberedSearchQuery: RxCollection<RememberedSearchQuery>;
  dataSources: RxCollection<DataSourceMeta>;
  formVersions: RxCollection<FormVersionEntry>;
  fieldmeta: RxCollection<FieldMeta>;
  unsyncedparentfields: RxCollection<UnsyncedParentField>;
};

const LOG_ALL_DATABASE_CALLS = false; // enable for debugging purposes only

export const BATCH_SIZES = {
  fields: { push: 500, pull: 500, stream: 500 },
  submissions: { push: 1, pull: 100, stream: 500 },
  migrations: 1_000,
};

export const closeAllConnections = async (): Promise<void> => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  // https://github.com/capacitor-community/sqlite/blob/master/docs/TypeORM-Usage.md#using-capacitor-sqlite-with-typeorm
  await CapacitorSQLite.checkConnectionsConsistency({
    dbNames: [], // expect no connections to be open
    openModes: [],
  });
  await new SQLiteConnection(CapacitorSQLite).closeAllConnections();
};

const getStorage = async (): Promise<RxStorage<any, any>> => {
  if (!Capacitor.isNativePlatform()) {
    return getRxStorageDexie();
  }
  const sqlite = new SQLiteConnection(CapacitorSQLite);
  return getRxStorageSQLite({ sqliteBasics: getSQLiteBasicsCapacitor(sqlite, Capacitor) });
};

const getKeyCompressionStorage = async (): Promise<RxStorage<any, any>> => {
  const storage = await getStorage();
  return wrappedKeyCompressionStorage({ storage });
};

const migrateCollectionsIfNeeded = async (collection: RxCollection): Promise<void> => {
  const isMigrationNeeded = await collection.migrationNeeded();
  if (isMigrationNeeded) {
    logger.log(`Migrating collection ${collection.name}`);
    await collection?.migratePromise(BATCH_SIZES.migrations);
    logger.log(`Done migrating ${collection.name}`);
  }
};

export const getDatabaseName = (userId: string): string => `moreapp-${userId}-rx15`;

export const createDatabase = async (dbName: string): Promise<RxDatabase<DBCollections>> => {
  let db;
  let collections;
  let storage;
  try {
    if (LOG_ALL_DATABASE_CALLS) {
      storage = wrappedLoggerStorage({
        storage: await getKeyCompressionStorage(),
        settings: {
          // can used to prefix all log strings, default=''
          prefix: "",

          /**
           * Be default, all settings are true.
           */

          // if true, it will log timings with console.time() and console.timeEnd()
          times: true,

          // if false, it will not log meta storage instances like used in replication
          metaStorageInstances: false,

          // operations
          bulkWrite: true,
          findDocumentsById: true,
          query: true,
          count: true,
          info: true,
          getAttachmentData: true,
          getChangedDocumentsSince: true,
          cleanup: true,
          close: true,
          remove: true,
        },
      });
    } else {
      storage = await getKeyCompressionStorage();
    }
    db = await createRxDatabase<DBCollections>({
      name: dbName,
      storage,
      cleanupPolicy: {},
      multiInstance: true,
      eventReduce: true,
      localDocuments: true,
    });
  } catch (e: any) {
    logger.error("Error setting up RxDB", e);
    throw new DatabaseError("initialization_failed", "Error setting up RxDB", { cause: e });
  }

  try {
    collections = await db.addCollections({
      submissions: {
        schema: submissionSchema,
        migrationStrategies: {
          1: migrationRxdb14,
          2: migrationSubmittedAt,
        },
        autoMigrate: false,
      },
      fields: {
        schema: fieldSchema,
        migrationStrategies: {
          1: migrationWithOrder,
          2: migrationRxdb14,
          3: migrationCompressedFields,
          4: migrationUploadStatus,
        },
        autoMigrate: false,
      },
      "remembered-fields": {
        schema: rememberedFieldSchema,
        migrationStrategies: {
          1: migrationRxdb14,
        },
        autoMigrate: false,
      },
      "remembered-search-query": {
        schema: rememberedSearchQuerySchema,
        autoMigrate: false,
      },
      datasources: {
        schema: dataSourceSchema,
        migrationStrategies: {
          1: migrationRxdb14,
          2: migrationLocalFallback,
        },
        autoMigrate: false,
      },
      "form-versions": { schema: formVersionSchema, autoMigrate: false },
      fieldmeta: {
        schema: fieldMetaSchema,
        migrationStrategies: {},
        autoMigrate: false,
      },
      unsyncedparentfields: {
        schema: unsyncedParentFieldSchema,
        autoMigrate: false,
      },
    });

    await migrateCollectionsIfNeeded(collections.submissions);
    await migrateCollectionsIfNeeded(collections.fields);
    await migrateCollectionsIfNeeded(collections["remembered-fields"]);
    await migrateCollectionsIfNeeded(collections.datasources);

    // TODO DEV-6207 cleanup after a while, assuring most users will have removed the useless 'fieldmeta' collection
    //  this was an ever-growing collection of field-references that was never cleaned, so it's important
    if (collections.fieldmeta) {
      await collections.fieldmeta.remove();
    }
  } catch (e: unknown) {
    logger.error("RxDB schema setup failed", e);
    throw new DatabaseError("initialization_schema_failed", "RxDB schema setup failed", { cause: e });
  }

  return db;
};

export const QUERIES = {
  SUBSCRIPTION: {
    submission: compress(`
    subscription onSubmissionChanged($seq: bigint) {
        app_submissions_stream(batch_size: ${BATCH_SIZES.submissions.stream}, cursor: {initial_value: {seq: $seq}, ordering: ASC}) {
          id
          customerId
          formId
          formVersionId
          description
          meta
          form
          task
          createdAt
          updatedAt
          viewedAt
          submittedAt
          status
          _deleted: deleted
          seq
        }
      }
    `),
    field: compress(`
      subscription onSubmissionFieldChanged($seq: bigint) {
        app_submission_fields_stream(batch_size: ${BATCH_SIZES.fields.stream}, cursor: {initial_value: {seq: $seq}, ordering: ASC}) {
          id
          data
          meta
          entry {
            id
            fieldId
          }
          entries(where: {deleted: {_eq: false}}) {
            id
            submissionId
            meta
            deleted
          }
          updatedAt
          createdAt
          submissionId
          _deleted: deleted
          status
          seq
        }
      }`),
  },
  PUSH: {
    submission: compress(`
    mutation InsertSubmission($submissions: [app_submissions_insert_input!]!, $fields: [app_submission_fields_insert_input!]!) {
        insert_app_submissions(
            objects: $submissions,
            on_conflict: {
                constraint: submissions_pkey,
                update_columns: [meta, form, description, deleted, status, viewedAt, submittedAt]
            }){
            returning {
              id
              deleted
            }
          }
        insert_app_submission_fields(
          objects: $fields,
          on_conflict: {
            constraint: submission_fields_pkey,
            update_columns: [data, meta, deleted, status]
          }) {
            affected_rows
          }
    }`),
    field: compress(`
    mutation InsertField($fields: [app_submission_fields_insert_input!]!) {
        insert_app_submission_fields(
            objects: $fields,
            on_conflict: {
              constraint: submission_fields_pkey,
              update_columns: [data, meta, deleted, status]
            }) {
              affected_rows
            }
     }`),
  },
  PULL: {
    check: `
        where: { seq: {_gt: $seq} },
        limit: $limit,
        order_by: {seq: asc}
      `,
    initialCheck: `
        where: {
          _and: {
            seq: {_gt: $seq}
            deleted: {_eq: false}
          }
        },
        limit: $limit,
        order_by: {seq: asc}
      `,
    submission: (initial: boolean): string =>
      compress(`
      query SubmissionPullQuery($seq: bigint, $limit: Int) {
        app_submissions(${initial ? QUERIES.PULL.initialCheck : QUERIES.PULL.check}) {
          id
          customerId
          formId
          formVersionId
          description
          meta
          form
          task
          createdAt
          updatedAt
          viewedAt
          submittedAt
          status
          _deleted: deleted
          seq
        }
      }`),
    fields: (initial: boolean): string =>
      compress(`
      query FieldPullQuery($seq: bigint, $limit: Int) {
        app_submission_fields(${initial ? QUERIES.PULL.initialCheck : QUERIES.PULL.check}) {
          id
          data
          meta
          entry {
            id
            fieldId
          }
          entries(where: {deleted: {_eq: false}}) {
            id
            submissionId
            meta
            deleted
          }
          updatedAt
          createdAt
          submissionId
          _deleted: deleted
          status
          seq
        }
      }`),
  },
  COUNT: {
    fields: (initial: boolean): string =>
      compress(`query CountFields($seq: bigint,$limit: Int) {
          app_submission_fields_aggregate(${initial ? QUERIES.PULL.initialCheck : QUERIES.PULL.check}) {
          aggregate {
             count
             }
            }
          }`),
    submissions: (initial: boolean): string =>
      compress(`query CountSubmissions($seq: bigint,$limit: Int) {
          app_submissions_aggregate(${initial ? QUERIES.PULL.initialCheck : QUERIES.PULL.check}) {
          aggregate {
             count
             }
          }
        }`),
  },
};
