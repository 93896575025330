import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounceValue, useReadLocalStorage } from "usehooks-ts";
import SearchContent from "./SearchContent";
import useSearchTerms from "../../../hooks/useSearchTerms";
import useFocus from "../../../hooks/useFocus";
import type { WidgetSearchProperties } from "../WidgetSearch";
import type { FormField } from "../../../types/FormVersion";
import type { DataSourceEntry } from "../../../types/Datasource";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { SearchField } from "../../../storybook/components/SearchField/SearchField";
import { Pill } from "../../../storybook/components/Pill/Pill";
import { Checkbox } from "../../../storybook/components/Checkbox/Checkbox";
import { Text } from "../../../storybook/components/Text/Text";
import OnlineSearchContent from "./OnlineSearchContent";
import useOnlineStatus from "../../../hooks/useOnlineStatus";

type SearchDrawerProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  query: string;
  setQuery: (value: string) => void;
  props: FormField<WidgetSearchProperties>;
  enabledEntryFields: string[];
  onChange: (value: DataSourceEntry) => void;
  setScanResult: (value: string | undefined) => void;
  scanResult?: string;
  entryId?: string;
  onCloseFocusId?: string;
};
const SEARCH_DEBOUNCE = 300;
const SearchDrawer: FC<SearchDrawerProps> = ({
  isOpen,
  setOpen,
  query,
  setQuery,
  props,
  enabledEntryFields,
  onChange,
  entryId,
  scanResult,
  setScanResult,
  onCloseFocusId,
}) => {
  const [debouncedQuery] = useDebounceValue<string>(query, SEARCH_DEBOUNCE);
  const [searchFieldRef, setFocus] = useFocus();
  const filterQueries = useSearchTerms(props.properties.filter_fields, entryId);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [isExactQuery, setExactQuery] = useState(false);
  // Don't yet enable the online functionality for production
  const experimentalOnlineSearchEnabled = useReadLocalStorage("onlineSearch");
  const { isOnline } = useOnlineStatus();

  const filters = useMemo<string[]>(() => {
    if (!scanResult) {
      return filterQueries ?? [];
    }
    if (!filterQueries) {
      return [scanResult];
    }
    return [...filterQueries, scanResult];
  }, [filterQueries, scanResult]);

  useEffect(() => {
    if (isOpen && setFocus) {
      setFocus();
    }
  }, [isOpen, setFocus]);

  return (
    <Drawer
      onCloseFocusId={onCloseFocusId}
      open={isOpen}
      contentPadding={false}
      header={{
        kind: "simple",
        title: t("SEARCH_SELECT_ITEM"),
        button: { kind: "icon", icon: "XIcon", onClick: () => setOpen(false) },
        content: initialized ? (
          <div className="mx-6 mb-4">
            <SearchField
              placeholder={t("SEARCH_PLACEHOLDER")}
              focusableRef={searchFieldRef}
              value={query}
              onChange={setQuery}
            />
            {filters.length > 0 && (
              <div className="mt-4">
                {filters.map((term) => (
                  <Pill key={term} icon="FilterIcon" appearance="brand" value={term} className="mr-1" />
                ))}
              </div>
            )}
            <Checkbox
              name={`${props.uid}-drawer-exact`}
              className="mx-0 mt-4"
              selected={isExactQuery}
              onChange={(e) => setExactQuery(e.target.checked)}
            >
              <Text>{t("SEARCH_EXACT_MATCH")}</Text>
            </Checkbox>
          </div>
        ) : undefined,
      }}
      initialFocus={searchFieldRef}
      onClose={() => {
        setOpen(false);
        setScanResult(undefined);
      }}
    >
      {experimentalOnlineSearchEnabled && isOnline ? (
        <OnlineSearchContent
          dataSourceId={props.properties?.data_source_configuration?.id}
          query={debouncedQuery}
          entryFields={enabledEntryFields}
          colors={props.properties.colors}
          filterQueries={filters}
          onChange={onChange}
          isExactQuery={isExactQuery}
          onInit={setInitialized}
        />
      ) : (
        <SearchContent
          dataSourceId={props.properties?.data_source_configuration?.id}
          query={debouncedQuery}
          entryFields={enabledEntryFields}
          colors={props.properties.colors}
          filterQueries={filters}
          onChange={onChange}
          isExactQuery={isExactQuery}
          onInit={setInitialized}
        />
      )}
    </Drawer>
  );
};

export default SearchDrawer;
